'use client';

import { BaseComp } from 'components';
import { API } from 'data';
import { useEffect, useState } from 'react';
import { RestAPI } from 'scripts';
import { LanguageEnum } from 'types';
import Error500Comp from 'tehranto/500/500.index';
import { Spin } from 'antd';
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  const [lang, setLang] = useState<LanguageEnum>();
  useEffect(() => {
    RestAPI.get<{
      lang: LanguageEnum;
    }>(API.middlewares.lang).then(res => setLang(res.data.lang)).catch(() => setLang(LanguageEnum.fa));
  });
  if (lang !== undefined) return <BaseComp lang={lang} profile={undefined}>
                <Error500Comp error={error} reset={reset} />
            </BaseComp>;else return <div className={`w-100 center-content min-h-100-vh`}>
                <Spin className="h-100 center-content d-flex-i" size="large" />
            </div>;
}